// Import createSlice from @reduxjs/toolkit
import { createSlice } from "@reduxjs/toolkit";

// Define initial state
const initialState = {
  printrtTyp: [],
  companyInfo: {},
  isDrawerAlways: false,
  decimalCount: 3,
  primaryCurrency: "",
  subCurrency: "",
  isADDon: false,
  allKot: false,
  isAccountsShow: false,
  taxUtilitiesListData: [],
};

// Create a slice
const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    // Action to set the array of printrtTyp
    setPrintrtTyp: (state, action) => {
      state.printrtTyp = action.payload;
    },
    // Action to set the companyInfo object
    setCompanyInfo: (state, action) => {
      state.companyInfo = action.payload;
    },
    // Action to set the drawer always on object
    setIsDrawerAlways: (state, action) => {
      state.isDrawerAlways = action.payload;
    },
    // Action to set the decimal value
    setDecimalCount: (state, action) => {
      state.decimalCount = action.payload;
    },
    // Action to set the primary currency value
    setPrimaryCurrency: (state, action) => {
      state.primaryCurrency = action.payload;
    },
    // Action to set the sub currency value
    setSubCurrency: (state, action) => {
      state.subCurrency = action.payload;
    },
    setCommonState(state, action) {
      Object.assign(state, action.payload);
    },
  },
});

// Export the actions and reducer
export const {
  setPrintrtTyp,
  setCompanyInfo,
  setIsDrawerAlways,
  setDecimalCount,
  setPrimaryCurrency,
  setSubCurrency,
  setCommonState,
} = commonSlice.actions;
export default commonSlice.reducer;
